import { useCallback, useEffect, useState } from 'react';
import { GroupSelect } from '../../components/Modules/GroupSelect';
import { Widget } from '../../components/Elements/Widget';
import { PhoneWidget } from '../../components/Elements/PhoneWidget';
import { api } from 'utils/fetch-wrapper';
import ToggleSwitch from 'components/Modules/ToggleSwitch';
import { AddEditPost } from 'components/Modules/AddEditPost';

export function Home() {
  const [sections, setSections] = useState(null);
  const [editMode, setEditMode] = useState(true);
  const [activePage, setActivePage] = useState(null);
  const [filter, setFilter] = useState([1, 2, 3, 4, 5, 6]);
  const [editElement, setEditElement] = useState(null);

  const fetchData = useCallback(async () => {
    const res = await api.get('/app/');
    setSections(res);
    if (!activePage) {
      setActivePage(res[0]);
    }
  }, [activePage]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const refresh = useCallback(() => {
    fetchData();
    // setEditElement(null);
  }, [fetchData]);

  return (
    <div className="flex p-5 items-start">
      <div className='flex-1 flex justify-end'>
        <Widget title="Oversikt">
          <div className="flex justify-between my-1 p-2 border rounded-lg border-gray">
            <p className='text-xl'>Redigeringsmodus</p>
            <div className='flex items-center'>
              <ToggleSwitch initialState={editMode} callback={() => setEditMode(!editMode)} />
            </div>
          </div>
          <p className='text-2xl mb-2'>Hvilke grupper skal vises?</p>
          <GroupSelect state={filter} setState={setFilter} />
        </Widget>
      </div>
      {sections && ( 
        <div className='flex-1 flex justify-center'>
          <PhoneWidget activePage={activePage} sections={sections} editMode={editMode} filter={filter} setActivePage={setActivePage} setEditElement={setEditElement} />
        </div>
      )}
      <div className='flex-1 flex justify-start'>
        { editElement && editMode &&
          // <EditPanel header="Rediger" element={editElement} />
          <AddEditPost element={editElement} IContent={!(editElement.sectionId === 2 || editElement.sectionId === 4)} ITimestamp={(editElement.sectionId === 2)} IFiles={!(editElement.sectionId === 2)} IGroups={!(editElement.sectionId === 3 || editElement.sectionId === 4)} callback={refresh} />
        }
      </div>
    </div>
  );
}
