import { useAppContext } from 'components/Wrappers/AppWrapper';
import ToggleSwitch from './ToggleSwitch';

export function GroupSelect({ state, setState }) {
  const { groups } = useAppContext();

  function handleChange(id) {
    if(state?.includes(id)) {
      setState(state.filter(x => x !== id));
    } else {
      setState([...state, id]);
    }
  }

  return (
    <>
      {groups?.map((elem, i) => (
        <div key={elem.id} className={`flex w-full justify-between p-1 ${i === 0 && 'rounded-t-lg'} ${i === groups.length-1 && 'border-b rounded-b-lg'} border-r border-t border-l border-gray`}>
          <p>{elem.title}</p>
          <ToggleSwitch initialState={state?.includes(elem.id)} callback={() => handleChange(elem.id)} />
        </div>
      ))}
    </>
  );
      
  // return (
  //   <div className="flex">
  //     <div className="flex flex-col w-full mr-1">
  //       <ToggleSwitch />
  //       <div className="flex items-center justify-between">
  //         <p>Voksen Camp</p>
  //         <input defaultChecked={state.includes(1)} className="ml-5" type="checkbox" onClick={() => handleChange(1)} />
  //       </div>
  //       <div className="flex items-center justify-between">
  //         <p>Youth Camp</p>
  //         <input defaultChecked={state.includes(2)} className="ml-5" type="checkbox" onClick={() => handleChange(2)} />
  //       </div>
  //       <div className="flex items-center justify-between">
  //         <p>Tweens Camp</p>
  //         <input defaultChecked={state.includes(3)} className="ml-5" type="checkbox" onClick={() => handleChange(3)} />
  //       </div>
  //       <div className="flex items-center justify-between">
  //         <p>Kids Camp</p>
  //         <input defaultChecked={state.includes(4)} className="ml-5" type="checkbox" onClick={() => handleChange(4)} />
  //       </div>
  //       <div className="flex items-center justify-between">
  //         <p>Mini Camp</p>
  //         <input defaultChecked={state.includes(5)} className="ml-5" type="checkbox" onClick={() => handleChange(5)} />
  //       </div>
  //     </div>
  //   </div>
  // );
}