import { useCallback, useEffect, useState } from 'react';
import { Nyhet } from './Posts/Nyhet';
import useWindowDimensions from 'components/Hooks/useWindowDimensions';
import { Info } from './Posts/Info';
import { ImageSlider } from './ImageSlider';
import { Program } from './Posts/Program';
import { MediaContainer } from './Posts/Media';

function DataMap({ data, filter, activePage, editMode, setEditElement, infoPage, setInfoPage }) {
  const [state, setState] = useState([]);

  // const tmpState = [
  //   {id: 1, title: '2022', content: null, location: null, timestamp: null, sectionId: 4 },
  //   {id: 2, title: '2021', content: null, location: null, timestamp: null, sectionId: 4,
  //     posts: [
  //       {id: 5, title: 'Voksen Camp', content: null, location: null, timestamp: null, postId: 2 },
  //       {id: 3, title: 'Youth Camp', content: null, location: null, timestamp: null, postId: 2 },
  //       {id: 6, title: 'Tweens Camp', content: null, location: null, timestamp: null, postId: 2 },
  //       {id: 4, title: 'Kids Camp', content: null, location: null, timestamp: null, postId: 2 },
  //     ],
  //     files: [
  //       { id: 1, url: 'https://simplistic-festival.s3.eu-central-1.amazonaws.com/c542a098-aa3c-4167-82a3-66bcb68d6c28', type: 'image/png' },
  //       { id: 2, url: 'https://simplistic-festival.s3.eu-central-1.amazonaws.com/c542a098-aa3c-4167-82a3-66bcb68d6c28', type: 'image/png' },
  //       { id: 3, url: 'https://simplistic-festival.s3.eu-central-1.amazonaws.com/c542a098-aa3c-4167-82a3-66bcb68d6c28', type: 'image/png' },
  //       { id: 4, url: 'https://simplistic-festival.s3.eu-central-1.amazonaws.com/c542a098-aa3c-4167-82a3-66bcb68d6c28', type: 'image/png' },
  //       { id: 5, url: 'https://simplistic-festival.s3.eu-central-1.amazonaws.com/c542a098-aa3c-4167-82a3-66bcb68d6c28', type: 'image/png' },
  //       { id: 6, url: 'https://simplistic-festival.s3.eu-central-1.amazonaws.com/c542a098-aa3c-4167-82a3-66bcb68d6c28', type: 'image/png' },
  //       { id: 7, url: 'https://simplistic-festival.s3.eu-central-1.amazonaws.com/c542a098-aa3c-4167-82a3-66bcb68d6c28', type: 'image/png' },
  //     ]
  //   },
  // ];
  
  useEffect(() => {
    const filtered = [];
    data?.map(x => {
      x.groups?.forEach(y => {
        if(filter.includes(y.id) && !filtered.includes(x)) {
          filtered.push(x);
        }
      });
    });
    if (activePage.title === 'Info') {
      setState(data.reverse());
    } else if (activePage.title === 'Program'){
      setState(filtered.sort((a, b) => {
        let x = new Date(a.timestamp);
        let y = new Date(b.timestamp);
        return x - y;
      }));
    } else if (activePage.title === 'Media') {
      setState(data);
    }
    else {
      setState(filtered);
    }
  }, [filter, data, activePage.title]);

  if (infoPage) {
    return (
      <div className='overflow-hidden flex flex-col h-full'>
        <div className='py-2 px-3 border-b border-b-gray shadow-sm'>
          <div className='w-6 hover:cursor-pointer' onClick={() => setInfoPage(null)}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 rotate-180" fill="none" viewBox="0 0 24 24" stroke="black" strokeWidth="1.6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3" />
            </svg>
          </div>
        </div>
        <div className='overflow-y-auto py-2 px-3'>
          <p className='text-2xl font-semibold'>{infoPage.title}</p>
          <p className='texl-lg mt-1'>{infoPage.content}</p>
          {infoPage.files.filter(x => x.type.split('/')[0] === 'image').length > 0 && 
          <div className='-mx-3 mt-3'>
            <ImageSlider images={infoPage.files.filter(x => x.type.split('/')[0] === 'image')} />
          </div>
          }
          {infoPage.files.filter(x => x.type.split('/')[0] === 'video').length > 0 && infoPage.files.filter(x => x.type.split('/')[0] === 'video').map(elem =>
            <div className='mt-2 -mx-3' key={elem.id}>
              <video className='w-full aspect-video' controls>
                <source src={elem.url} type={elem.type} />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
          {infoPage.files.filter(x => x.type.split('/')[0] === 'audio').length > 0 && infoPage.files.filter(x => x.type.split('/')[0] === 'audio').map(elem =>
            <div className='mt-2 -mx-3' key={elem.id}>
              <video className='w-full h-12' controls>
                <source src={elem.url} type={elem.type} />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={'flex-1 overflow-hidden overflow-y-auto bg-gray py-2 ' + (activePage.title === 'Info' ? 'px-2' : 'flex-col flex')}>
      { editMode &&
        <div className={(activePage.title === 'Info' ? 'inline-block w-1/2 p-2 overflow-hidden' : '')}>
          {(activePage.title === 'Nyheter') && <Nyhet newElement setEditElement={setEditElement} />}
          {(activePage.title === 'Program') && <Program newElement setEditElement={setEditElement} />}
          {(activePage.title === 'Info') && <Info newElement setEditElement={setEditElement} />}
          {/* {(activePage.title === 'Media') && <Media newElement setEditElement={setEditElement} />} */}
        </div>
      }
      {state?.map(elem => (
        <div key={elem.id} className={(activePage.title === 'Info' ? 'inline-block w-1/2 p-2 overflow-hidden' : '')}>
          {(activePage.title === 'Nyheter') && <Nyhet post={elem} editMode={editMode} setEditElement={setEditElement} />}
          {(activePage.title === 'Program') && <Program post={elem} editMode={editMode} setEditElement={setEditElement} />}
          {(activePage.title === 'Info') && <Info post={elem} editMode={editMode} setEditElement={setEditElement} setInfoPage={setInfoPage} />}
        </div>
      ))}
      {activePage.title === 'Media' && <MediaContainer posts={state} editMode={editMode} setEditElement={setEditElement} />}
    </div>
  );
}

export function PhoneWidget({ activePage, sections, editMode, filter, setActivePage, setEditElement }) {
  const [data, setData] = useState(null);
  const [infoPage, setInfoPage] = useState(null);
  const {height} = useWindowDimensions();

  const fetchData = useCallback(async () => {
    // const res = await api.get('/app/');
    const filtered = sections?.filter(elem => elem.id === activePage.id)[0].posts;
    const sorted = filtered.sort(function(a,b){
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    setData(sorted);

  }, [activePage, sections]);

  function changeActivePage(item) {
    setActivePage(item);
    setInfoPage(null);
  }

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (infoPage) {
      const newInfoPage = data.filter(x => x.id === infoPage.id)[0];
      setInfoPage(newInfoPage);
    }
  }, [infoPage, data]);

  return (
    <div className="border-8 m-5 rounded-3xl overflow-hidden flex flex-col" style={{ height: ((height-200) > 560) ? height-200 : 560 , width: ((height-200) > 560) ? (height-200)/2 : 280 }}>
      <p className="px-3 py-2 bg-white text-4xl border-b-2 border-b-lightgray">{activePage.title}</p>
      <DataMap data={data} filter={filter} activePage={activePage} editMode={editMode} setEditElement={setEditElement} infoPage={infoPage} setInfoPage={setInfoPage} />
      <div className="bg-white px-2 py-1 flex flex-row border-t-2 border-t-lightgray">
        {/* {sections.map(item => 
          <div key={item.id} className={`flex-1 flex justify-center flex-col hover:bg-blue rounded-lg hover:bg-opacity-20 cursor-pointer ${item.id === activePage.id && 'text-blue'}`} onClick={() => changeActivePage(item)}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 mx-auto" fill="none" viewBox="0 0 24 24" stroke={item.id === activePage.id ? '#3897D1' : 'black'} strokeWidth="1.6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
            </svg>
            <p className="text-center text-sm">{item.title}</p>
          </div>
        )} */}
        <div className={`flex-1 flex justify-center flex-col hover:bg-blue rounded-lg hover:bg-opacity-20 cursor-pointer ${1 === activePage.id && 'text-blue'}`} onClick={() => changeActivePage(sections[0])}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 mx-auto" fill="none" viewBox="0 0 24 24" stroke={1 === activePage.id ? '#3897D1' : 'black'} strokeWidth="1.6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
          </svg>
          <p className="text-center text-sm">Nyheter</p>
        </div>
        <div className={`flex-1 flex justify-center flex-col hover:bg-blue rounded-lg hover:bg-opacity-20 cursor-pointer ${2 === activePage.id && 'text-blue'}`} onClick={() => changeActivePage(sections[1])}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 mx-auto" fill="none" viewBox="0 0 24 24" stroke={2 === activePage.id ? '#3897D1' : 'black'} strokeWidth="1.6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
          </svg>
          <p className="text-center text-sm">Program</p>
        </div>
        <div className={`flex-1 flex justify-center flex-col hover:bg-blue rounded-lg hover:bg-opacity-20 cursor-pointer ${3 === activePage.id && 'text-blue'}`} onClick={() => changeActivePage(sections[2])}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 mx-auto" fill="none" viewBox="0 0 24 24" stroke={3 === activePage.id ? '#3897D1' : 'black'} strokeWidth="1.6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <p className="text-center text-sm">Info</p>
        </div>
        <div className={`flex-1 flex justify-center flex-col hover:bg-blue rounded-lg hover:bg-opacity-20 cursor-pointer ${4 === activePage.id && 'text-blue'}`} onClick={() => changeActivePage(sections[3])}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 mx-auto" fill="none" viewBox="0 0 24 24" stroke={4 === activePage.id ? '#3897D1' : 'black'} strokeWidth="1.6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z" />
          </svg>
          <p className="text-center text-sm">Media</p>
        </div>
      </div>
    </div>
  );
}