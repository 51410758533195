import { Spinner } from 'components/Elements/Spinner';
import { useMemo } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { api } from 'utils/fetch-wrapper';
import { GroupSelect } from './GroupSelect';

export function AddEditPost({ header, element, ITimestamp, IFiles, IContent, IGroups, callback }) {
  const [submitting, setSubmitting] = useState(false);
  const [form, setForm] = useState(null);

  const defaultForm = useMemo(() => ({
    title: element.title ? element.title : '',
    content: element.content ? element.content : '',
    timestamp: element.timestamp ? new Date(element.timestamp).toISOString().substring(0, 16) : '',
    groups: element.groups ? element.groups.map(elem => { return elem.id; }) : [],
    files: element.files ? element.files : [],
    sectionId: element.sectionId ? element.sectionId : null,
    postId: element.postId ? element.postId : null
  }), [element]);

  useEffect(() => {
    setForm(defaultForm);
  }, [defaultForm]);

  const updateForm = useCallback(param => {
    setForm({ ...form, ...param });
  }, [form]);
  
  async function submit() {
    const { title, content, groups, files, timestamp, sectionId, postId } = form;
    if (!title) return;
    setSubmitting(true);
    const res = await api.post('/app/', { id: element?.id, title, content, sectionId, postId, groups, timestamp: timestamp && new Date(+new Date(timestamp) + 7200000).toISOString() });
    if (files && res) {
      const keys = Object.keys(files);
      let fileArray = keys.map(key => {
        return files[key];
      });
      for (let file of fileArray) {
        if (!file.id) {
          let formData = new FormData();
          formData.append('file', file);
          await api.postFormData(`/app/files/${res.id}`, formData);
        }
      }
    }
    callback();
    setSubmitting(false);
    // TODO: Add new post with images to existing posts frontend.
  }

  const removePost = useCallback(async () => {
    await api.delete(`/app/${element.id}`);
    callback();
  }, [element, callback]);

  const removeFile = useCallback(async (fileId) => {
    await api.delete(`/app/file/${fileId}`);
    updateForm({ files: form?.files.filter(file => file.id !== fileId) });
    callback();
  }, [form, updateForm, callback]);

  const removeLocalFile = useCallback(async (index) => {
    updateForm({ files: form?.files.filter(elem => form?.files.indexOf(elem) !== index) });
  }, [form, updateForm]);

  return (
    <div className="shadow-xl border border-gray p-5 pt-2 m-5 h-full rounded-lg w-96">
      {header && <p className="py-2 text-4xl">{header}</p>}
      {element &&
        <form className='w-full'>
          <div className='pt-2 space-y-1'>
            <p className='text-xl'>Tittel:</p>
            <input className='text-lg border-2 w-full hover:text-black focus:text-black border-gray rounded-md px-2 py-1 text-darkgray' type="text" name="title" value={form?.title} onChange={e => updateForm({ title: e.target.value })} />
          </div>
          {IContent && <div className='pt-2 space-y-1'>
            <p className='text-xl'>Innhold:</p>
            <textarea className='text-lg border-2 w-full h-36 hover:text-black focus:text-black border-gray rounded-md px-2 py-1 text-darkgray' type="text" name="title" value={form?.content} onChange={e => updateForm({ content: e.target.value })} />
          </div>}
          {ITimestamp && <div className='pt-1 space-y-1'>
            <p className='text-xl'>Tidspunkt:</p>
            <input className='w-full h-full rounded-md border-2 border-gray mt-1 px-2 py-1 text-darkgray hover:text-black focus:text-black' type="datetime-local" value={form?.timestamp} onChange={e => updateForm({ timestamp: e.target.value })} />
          </div> }
          {IFiles &&
            <div className='pt-2'>
              <p className='text-xl'>Media:</p>
              <div className='flex-row flex flex-wrap -mx-2'>
                {form?.files?.map((elem, index) =>
                  <div className='w-1/3' key={elem.key}>
                    <div className='overflow-hidden m-2 cursor-no-drop'
                      style={{
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
                      }}
                      onClick={() => elem.id ? removeFile(elem.id) : removeLocalFile(index)}
                    >
                      {elem?.type?.split('/')[0] === 'image' && <img src={elem.url ? elem.url : URL.createObjectURL(elem)} alt="Bilde" />}
                      {!(elem?.type?.split('/')[0] === 'image') && 
                        <>
                          <video src={elem.url ? elem.url : URL.createObjectURL(elem)} type={elem.type} className='aspect-video' />
                          <p className='text-xs text-center m-px first-letter:uppercase'>{elem?.type?.split('/')[0]}</p>
                        </>
                      }
                    </div>
                  </div>
                )}
                <div className='w-1/3'>
                  <label className="overflow-hidden m-2 cursor-pointer aspect-video flex justify-center hover:bg-lightgray"  
                    style={{
                      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
                    }}>
                    <span className="text-4xl font-semibold my-auto mb-3">+</span>
                    <input
                      className="hidden"
                      type="file"
                      name="file"
                      onChange={(e) => updateForm({ files: [...form.files, ...e.target.files] })}
                      multiple
                    />
                  </label>
                </div>
              </div>
            </div>
          }
          {IGroups && <div className="mt-3">
            <p className="text-xl mb-2">Gruppe:</p>
            <GroupSelect state={form?.groups} setState={e => updateForm({ groups: e })} />
          </div>}
          <div className="px-3 py-2 mt-5 bg-blue text-white rounded-md font-semibold text-center cursor-pointer hover:opacity-90 flex justify-center" onClick={() => submit()}>
            {!submitting ? 'Lagre' : <Spinner color='white' />}
          </div>
          {element && <div className="px-3 py-2 mt-2 text-white font-semibold bg-red rounded-md text-center cursor-pointer hover:opacity-90 flex justify-center" onClick={() => removePost()}>
            {!submitting ? 'Slett' : <Spinner color='white' />}
          </div>}
        </form>
      }
    </div>
  );
}