import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { api } from 'utils/fetch-wrapper';

const AppContext = createContext({
  groups: null,
  user: null,
  setUser: () => {},
  // addOrUpdatePostInSection: () => {},
  isLoading: false
});

let refreshTokenTimeout;

function startRefreshTokenTimer(user, callback) {
  // parse json object from base64 encoded jwt token
  const jwtToken = JSON.parse(atob(user.jwtToken.split('.')[1]));

  // set a timeout to refresh the token a minute before it expires
  const expires = new Date(jwtToken.exp * 1000);
  const timeout = expires.getTime() - Date.now() - 60 * 1000;
  refreshTokenTimeout = setTimeout(callback, timeout);
}

function stopRefreshTokenTimer() {
  clearTimeout(refreshTokenTimeout);
}

export function AppWrapper({ children }) {
  // const [sections, setSections] = useState(null);
  const [groups, setGroups] = useState(null);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // const addOrUpdatePostInSection = useCallback((sectionId, params) => {
  //   const newSections = [...sections];
  //   newSections.map(section => {
  //     if (section.id === sectionId) {
  //       const existingPost = section.posts.filter(elem => elem.id === params.id)[0];
  //       if (existingPost) {
  //         section.posts.filter(elem => elem.id === params.id)[0] = { ...existingPost, ...params };
  //       } else {
  //         section.posts.push(params);
  //       }
  //     }
  //     return section;
  //   });
  // }, [sections]);

  const updateUser = useCallback(async params => {
    setUser(params);
    localStorage.setItem('user', JSON.stringify(params));
  }, []);

  const refreshToken = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await api.post('/accounts/refresh-token', {});
      if (res) {
        // Set user to localstorage
        // localStorage.setItem('user', JSON.stringify(res));
        updateUser(res);
        startRefreshTokenTimer(res, refreshToken);
      }
    } catch (err) {
      console.log(err);
      console.log('Invalid refreshToken.');
    }
    setIsLoading(false);
  }, [updateUser]);

  const retrieveGroups = useCallback(async () => {
    api.get('/app/groups', user).then(res => {
      setGroups(res);
    }).catch(err => {});
  }, []);

  useEffect(() => {
    refreshToken();
    retrieveGroups();
  }, [refreshToken, retrieveGroups]);

  const logoutUser = useCallback(async () => {
    await api.post('/accounts/logout');
    setUser(null);
    stopRefreshTokenTimer();
    localStorage.removeItem('user');
  }, []);

  const state = useMemo(() => {
    const s = {
      groups,
      user,
      setUser: updateUser,
      logoutUser,
      isLoading,
      // addOrUpdatePostInSection
    };

    return s;
  }, [user, logoutUser, isLoading, groups, updateUser]);

  return (
    <AppContext.Provider value={state}>
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}