import { useState } from 'react';
import { Switch } from '@headlessui/react';
import { useCallback } from 'react';
import { useEffect } from 'react';

export default function ToggleSwitch({ initialState, callback }) {
  const [enabled, setEnabled] = useState(null);

  useEffect(() => {
    setEnabled(initialState);
  }, [initialState]);

  const updateState = useCallback(async () => {
    callback(!enabled);
    setEnabled(!enabled);
  }, [enabled, callback]);

  return (
    <Switch
      checked={enabled}
      onChange={updateState}
      className={`${enabled ? 'bg-blue' : 'bg-gray'}
          relative inline-flex h-[24px] w-[40px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={`${enabled ? 'translate-x-4' : 'translate-x-0'}
            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
      />
    </Switch>
  );
}