import { useState } from 'react';
import { ImageSlider } from '../ImageSlider';

export function MediaContainer({ posts, editMode, setEditElement }) {
  const [state, setState] = useState(null);

  return (
    <Media post={state} posts={state ? state.posts : posts} setPost={setState} setEditElement={setEditElement} editMode={editMode} />
  );
}


export function Media({ post, posts, editMode, setEditElement, setPost }) {
  console.log(posts);
  function handleClick(elem) {
    setPost(elem);
    setEditElement(elem);
  }
  return (
    <>
      {post && <div className='py-2 px-3 border-b border-b-gray bg-white shadow-sm -mt-2 mb-2 flex'>
        <div className='w-6 hover:cursor-pointer' onClick={() => setPost(null)}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 rotate-180" fill="none" viewBox="0 0 24 24" stroke="black" strokeWidth="1.6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3" />
          </svg>
        </div>
        <div className='ml-auto'><p className='font-bold'>{post.title}</p></div>
      </div>}
      <div className='mx-2'>
        {editMode && <div className='p-2'>
          <div className='flex bg-white w-full h-full rounded-xl shadow-md hover:bg-lightgray cursor-pointer' onClick={() => setEditElement({ sectionId: 4, postId: post ? post.id : null })}>
            <div className="m-auto mb-1">
              <p className='text-4xl font-semibold text-center'>+</p>
            </div>
          </div>
        </div>}
        {posts?.map(elem => (
          <div key={elem.id}>
            {(!elem.postId || (post?.id === elem.postId)) && (
              <div key={elem.id} className='p-2' onClick={() => handleClick(elem)}>
                <div className='flex p-3 bg-white w-full h-full rounded-xl shadow-md hover:bg-lightgray cursor-pointer' >
                  <div className="my-auto">
                    <p className='text-sm font-semibold'>{elem.title}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
        {post && post.files && 
          <div className='p-1'>
            {post.files.filter(x => x.type.split('/')[0] === 'image').map(elem => (
              <div key={elem.id} className='inline-block w-1/3 p-1 overflow-hidden'>
                <div className='bg-white rounded-xl aspect-square overflow-hidden'>
                  <img src={elem.url} className='object-cover h-full w-full' />
                </div>
              </div>
            ))}
            {post.files.filter(x => x.type.split('/')[0] === 'video').map(elem =>
              <div className='m-1' key={elem.id}>
                <video className='w-full aspect-video' controls>
                  <source src={elem.url} type={elem.type} />
                  Your browser does not support the video tag.
                </video>
              </div>
            )}
            {post.files.filter(x => x.type.split('/')[0] === 'audio').map(elem =>
              <div className='m-1' key={elem.id}>
                <video className='w-full h-12' controls>
                  <source src={elem.url} type={elem.type} />
                  Your browser does not support the video tag.
                </video>
              </div>
            )}
          </div>
        }
      </div>
    </>
  );
}