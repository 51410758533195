import config from 'config';

export const api = {
  get,
  post,
  postFormData,
  put,
  delete: _delete,
};

function get(url) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(config.api + url, requestOptions).then(handleResponse);
}

function post(url, body) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    credentials: 'include',
    body: JSON.stringify(body),
  };
  return fetch(config.api + url, requestOptions).then(handleResponse);
}

function postFormData(url, body) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Access-Control-Allow-Origin': '*', ...authHeader() },
    credentials: 'include',
    body: body,
  };
  return fetch(config.api + url, requestOptions).then(handleResponse);
}

function put(url, body) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(body),
  };
  return fetch(config.api + url, requestOptions).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };
  return fetch(config.api + url, requestOptions).then(handleResponse);
}

// helper functions

function authHeader() {
  // return auth header with jwt if user is logged in and request is to the api url
  const user = JSON.parse(localStorage.getItem('user'));
  const isLoggedIn = user && user.jwtToken;
  // const isApiUrl = url.startsWith(config.api);
  if (isLoggedIn) {
    return { Authorization: `Bearer ${user.jwtToken}` };
  } else {
    return {};
  }
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
