import { NavLink } from 'react-router-dom';
import logo from 'assets/logo.png';
import { useAppContext } from 'components/Wrappers/AppWrapper';

export default function Header() {
  const { logoutUser } = useAppContext();

  return (
    <div className="w-full flex h-20 bg-blue items-center">
      <div className="flex space-x-10 mx-10 h-14 place-items-center justify-between w-full">
        <div className="">
          <img className="h-10" src={logo} />
        </div>
        <nav className="flex space-x-10 lowercase font-bold items-center">
          <NavLink to="/" className="text-white font-normal">Innhold</NavLink>
          <button className="text-white py-2 px-3 border rounded-lg" onClick={() => {
            logoutUser();
          }}>logg ut</button>
          {/* <NavLink to="/statistikk">Statistikk</NavLink>
          {user && user.role !== 'User' && <NavLink to="/admin">Admin</NavLink>} */}
        </nav>
      </div>
    </div>
  );
}