import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export function ImageSlider({ images }) {
  return (
    <Carousel 
      showArrows={true} 
      showThumbs={false}
      showStatus={false}
    >
      {images?.map(image => (
        <div key={image.id} className="flex justify-center">
          <img className="object-cover w-full aspect-[16/11]" key={image.id} src={image.url} alt={image.type} />
        </div>
      ))}
    </Carousel>
  );
}

export function ImageSlider2({ images }) {
  return (
    <AliceCarousel
      autoPlayInterval={2000}
      autoPlay={false}
      fadeOutAnimation={true}
      mouseTrackingEnabled={true}
      disableAutoPlayOnAction={true}
      disableButtonsControls
    >
      {images?.map(image => (
        <div key={image.id} className="flex justify-center">
          <img className="object-contain w-full h-72" key={image.id} src={image.url} alt={image.type} />
        </div>
      ))}
    </AliceCarousel>
  );
}