export function Info({ newElement, post, editMode, setEditElement, setInfoPage }) {
  if (newElement) {
    return (
      <div className='h-[5.5rem]'>
        <div className='flex bg-white w-full h-full rounded-xl shadow-md hover:bg-lightgray cursor-pointer' onClick={() => setEditElement({ sectionId: 3 })}>
          <div className="m-auto">
            <p className='text-4xl font-semibold text-center'>+</p>
          </div>
        </div>
      </div>
    );
  }

  const { title, files } = post;

  function setPage(post) {
    setEditElement(post);
    setInfoPage(post);
  }

  return (
    <div className='h-[5.5rem]'>
      <div style={{
        backgroundImage: files.filter(x => x.type.split('/')[0] === 'image')[0] ? 'url('+files.filter(x => x.type.split('/')[0] === 'image')[0].url+')' : null,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }} 
      className={'flex bg-white w-full h-full rounded-xl shadow-md ' + (editMode ? 'hover:bg-lightgray cursor-pointer' : 'cursor-auto')} onClick={() => (editMode ? setPage(post) : null)}
      >
        <div className="px-3 py-2 mt-auto">
          <p className={'font-bold text-sm text-black rounded-lg'}>{title}</p>
        </div>
      </div>
    </div>
  );
}