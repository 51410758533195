export function Program({ newElement, post, editMode, setEditElement }) {
  if (newElement) {
    return (
      <div className='bg-white my-1 pb-2 hover:bg-lightgray cursor-pointer' onClick={() => setEditElement({ sectionId: 2 })}>
        <p className='text-4xl py-1 px-3 font-semibold text-center'>+</p>
      </div>
    );
  }

  const { title, content, files, groups } = post;
  
  return (
    <div className={'bg-white my-1 pb-2 ' + (editMode ? 'hover:bg-lightgray cursor-pointer' : 'cursor-auto')} onClick={() => (editMode ? setEditElement(post) : null)}>
      <div className="px-3 py-2 relative">
        <p className="font-bold text-md">{title}</p>
        <div className="flex">
          <p className="text-xs">{new Date(+new Date(post.timestamp)-7200000).toLocaleString('no-NO', {dateStyle: 'short', timeStyle: 'short'})}</p>
          <div className='flex-row flex ml-auto'>
            {groups?.map(group => (
              <p className="text-xs text-darkgray ml-2" key={group.id}>{group.title.split(' ')[0]}</p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}