import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

import { useAppContext } from 'components/Wrappers/AppWrapper';
import Button from 'components/Elements/Button';
import toast from 'react-hot-toast';
import { api } from 'utils/fetch-wrapper';

export function Login() {
  const { setUser } = useAppContext();

  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Ugyldig e-postadresse').required('Skriv inn e-post'),
    password: Yup.string().required('Skriv inn passord'),
  });

  async function onSubmit({ email, password }, { setSubmitting }) {
    setSubmitting(true);
    try {
      const res = await api.post('/accounts/authenticate', { email, password });
      setUser(res);
    } catch(err) {
      toast.error(err.toString());
    }
    setSubmitting(false);
  }

  return (
    <div className="flex justify-center mt-40">
      <div className="bg-white p-10 shadow rounded-md">
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ errors, touched }) => (
            <Form className='w-80'>
              <p className="text-5xl mb-6 leading-none">Logg inn</p>
              <div className="">
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2 ml-px">E-post</label>
                  <Field
                    name="email"
                    type="text"
                    className={
                      'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' +
                  (errors.email && touched.email ? ' border-red-600' : '')
                    }
                  />
                  {/* <ErrorMessage name="email" component="div" className="text-red-500" /> */}
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2 ml-px">Passord</label>
                  <Field
                    name="password"
                    type="password"
                    className={
                      'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' +
                  (errors.password && touched.password ? ' border-red-600' : '')
                    }
                  />
                  {/* <ErrorMessage name="password" component="div" className="text-red-500" /> */}
                </div>
                <div className="mt-8 block xs:flex">
                  <div className="flex h-8 space-x-2">
                    <Button filled type="submit">
                      Logg inn
                    </Button>
                    {/* <Button onClick={() => navigate('/')}/> */}
                  </div>
                  {/* <Link to="../forgot-password" className="flex mt-2 xs:mt-0 ml-auto">
                <p className="my-auto text-blue-500 hover:text-blue-800 hover:underline">Glemt&nbsp;passord?</p>
              </Link> */}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
