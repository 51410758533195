// import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { Home } from './pages/Home';
import { Login } from './pages/Login';
import { useAppContext } from 'components/Wrappers/AppWrapper';
import Header from 'components/Modules/Header';
import Footer from 'components/Modules/Footer';
// import { Nyheter } from 'pages/Nyheter';
import { Toaster } from 'react-hot-toast';

function App() {
  const { isLoading, user } = useAppContext();

  if(isLoading) {
    return <></>;
  }

  if(!user && !isLoading) {
    return (
      <>
        <Toaster />
        <Login />
      </>
    );
  }
  
  return (
    <>
      <Toaster/>
      <Header />
      <div className="flex-grow bg-white">
        <Routes>
          <Route index element={<Home />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;
